import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['zipcode', 'address_line1', 'number', 'district', 'city', 'state'];
    loading = false;

    async searchZipcode() {
        const zipcode = this.zipcodeTarget.value;

        if (zipcode.length < 8) {
            return;
        }

        const url = `https://viacep.com.br/ws/${zipcode}/json/`;

        this.loading = true;

        try {
            const response = await fetch(url)
            const data = await response.json();

            if (data.erro) {
                return;
            }

            this.address_line1Target.value = data.logradouro;
            this.districtTarget.value = data.bairro;
            this.cityTarget.value = data.localidade;
            this.stateTarget.value = data.uf;

            this.numberTarget.focus()
        } catch (e) {
            console.error(e)
        } finally {
            this.loading = false;
        }

    }
}

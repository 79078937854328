// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo'
Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
import '../controllers'

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import "@tabler/core"